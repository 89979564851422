<template>
    <div class="monomerProgress">
        <div class="monomerProgressCon" v-if="data.images_data.length>0">
            <div class="info">
                <div class="time">
                    {{ data.created }}
                </div>
                <div class="person">
                    上传人:{{ data.creator }}
                </div>
            </div>
            <div class="split-line">
                <i class="arrow"></i>
            </div>
            <div class="img-con">
                <ul>
                    <li class="el_left" v-for="item in urls" :key="item">
                        <img :src="`${item}`">
                    </li>
                </ul>
            </div>
        </div>
        <div class="default" v-else></div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            data: {
                images_data: [],
                baseUrl: '',
            },
            urls: [],
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 拉取工程单体进度信息
        getEngineeringMonomer() {
            this.$axios
                .get('/interfaceApi/sale/engineering_progress/get_engineering_monomer/' + this.extr.emid)
                .then(res => {
                    if (res) {
                        let arr = [];
                        if (res.images_data) {
                            arr = res.images_data.split(',');
                        }
                        res.images_data = arr;
                        this.data = res;
                        this.$nextTick(() => {
                            arr.forEach(item => {
                                this.getFileDetailFun(item);
                            });
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getFileDetailFun(id) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    this.urls.push(res.preview_url);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        this.baseUrl = this.FILE.FILE_BASE_URL() + 'file/';
        this.getEngineeringMonomer();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.monomerProgress
    height 100%
    overflow hidden
    padding .2rem
    .monomerProgressCon
        height 100%
        position relative
        .info
            width 1.5rem
            float left
            padding-top .2rem
            text-align center
            .time
                font-size .18rem
            .person
                font-size .16rem
                color #979EA7
        .split-line
            float left
            width 1px
            background #D7D7D7
            height 100%
            position relative
            .arrow
                position: absolute;
                z-index 10
                left 17px
                top .3rem
                &:after,&:before
                    position: absolute;
                    content: '';
                    border-top: 8px transparent dashed;
                    border-right: 8px transparent dashed;
                    border-bottom: 8px transparent dashed;
                    border-left: 8px #fff solid;
                &:before
                    border-left: 8px #ccc solid;
                    right 0
                &:after
                    right: 1px; /*覆盖并错开1px*/
                    border-left: 8px #fff solid;
                    z-index 10
        .img-con
            float left
            width calc(100% - 1.9rem)
            margin-left .3rem
            li
                margin .1rem
                img
                    width 1.38rem
                    height 1.38rem
    .default
        background url(./../../images/progess_default.png) no-repeat
        background-position center center
        height 100%
</style>